
//674357219370735 fb app id real
//2049822855318927 test app id fb
import {
  defineComponent,
  ref,
  onMounted,
  onUpdated,
  reactive,
  computed,
} from "vue";

//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";
import axios from "axios";
import { ErrorMessage, Field, Form, useField, useForm } from "vee-validate";
import store from "@/store";
import i18n from "@/core/plugins/i18n";
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import { func } from "@/core/plugins/globalfunc";

declare const window: any;
export default defineComponent({
  name: "customers-listing",
  mixins: [func],
  components: {
    //Datatable,
  },
  setup() {
    //Create form validation object
    const { t, te, i18n } = useI18n();
    const showFbError = ref(false);

    // Define a validation schema
    const schema = Yup.object({
      username: Yup.string()
        .min(3, "minUsername")
        .max(15, "maxUsername")
        .required("requiredUsername")
        .matches(/^\S*$/, 'noSpaceAllow')
        .test(
          "Unique Username",
          "usedUsername", // <- key, message
          function (value) {
            return new Promise((resolve, reject) => {
              axios
                .post(
                  `https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=register&action=checkUsername`,
                  JSON.stringify({ username: username.value })
                )
                .then((res) => {
                  if (res.data == "pass") {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                })
                .catch(() => {
                  resolve(false);
                });
            });
          }
        ),
      password: Yup.string()
        .min(6, "minPassword")
        .max(18, "maxPassword")
        .required("requiredPassword"),
      confirmPassword: Yup.string()
        .min(6, "minPassword")
        .max(18, "maxPassword")
        .required("requiredPassword")
        .oneOf([Yup.ref("password"), null], "matchPassword"),
      email: Yup.string()
        .email("emailFormat")
        .required("requiredEmail")
        .test(
          "Unique Email",
          "usedEmail", // <- key, message
          function (value) {
            return new Promise((resolve, reject) => {
              axios
                .post(
                  `https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=register&action=checkEmail`,
                  JSON.stringify({ email: email.value })
                )
                .then((res) => {
                  if (res.data == "pass") {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                })
                .catch(() => {
                  resolve(false);
                });
            });
          }
        ),
      mobile: Yup.string().required("requiredMobile"),
    });

    // Create a form context with the validation schema
    const { errors, handleSubmit, validate } = useForm({
      validationSchema: schema,
    });

    // No need to define rules for fields
    //const username = ref('');
    const { value: username, errorMessage: usernameError } =
      useField<string>("username");
    const { value: password, errorMessage: passwordError } =
      useField<string>("password");
    const { value: confirmPassword, errorMessage: confirmPasswordError } =
      useField<string>("confirmPassword");
    const { value: email, errorMessage: emailError } =
      useField<string>("email");
    const { value: mobile, errorMessage: mobileError } =
      useField<string>("mobile");

    return {
      //login,
      validate,
      errors,
      showFbError,
      username,
      usernameError,
      password,
      passwordError,
      confirmPassword,
      confirmPasswordError,
      email,
      emailError,
      mobile,
      mobileError,
    };
  },
  data() {
    return {};
  },
  computed: {
    tokenValue() {
      return store.state.token;
    },
    uidValue() {
      return store.state.uid;
    },
    usernameValue() {
      return store.state.username;
    },
    fbidValue() {
      return store.state.fbid;
    },
    prevRouteValue() {
      if (
        store.state.prevRoute.name == "login" ||
        store.state.prevRoute.name == "logout" ||
        store.state.prevRoute.name == "register" ||
        store.state.prevRoute.name == "forgot" ||
        store.state.prevRoute.name == "reset"
      ) {
        return;
      } else {
        return store.state.prevRoute.fullPath;
      }
    },
  },
  mounted() {
    if (store.state.uid) {
      ElMessage.success(this.$t("alreadyLogin"));
      this.$router.push("/");
    }
  },
  methods: {
    submitRegister(inputFbid) {
      this.validate().then((values) => {
        if (values.valid) {
          axios
            .post(
              "https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=register&action=register",
              JSON.stringify({
                username: encodeURI(this.username),
                password: this.password,
                email: this.email,
                mobile: this.mobile,
                fbid: inputFbid,
              })
            )
            .then((response) => {
              if (response.data === "fail" || !response.data) {
                ElMessage.error(this.$t("registerFail"));
              } else if (response.data === "facebookExist") {
                ElMessage.error(this.$t("facebookExist"));
              } else if (response.data === "success") {
                ElMessage({
                  message: this.$t("registerSuccess"),
                  type: "success",
                });
                this.$router.push({ name: "login" });
              } else {
                ElMessage.error(this.$t("registerFail"));
              }
            })
            .catch((e) => {
              ElMessage.error(this.$t("registerFail"));
            });
        }
      });
    },
    fbRegister() {
      this.showFbError = true;
      window.facebookConnectPlugin.login(["public_profile"],(response) => {
        if (response.authResponse.userID) {
          axios
            .post(
              `https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=register&action=existFbid`,
              JSON.stringify({ fbid: response.authResponse.userID })
            )
            .then((res) => {
              if (res.data == "pass") {
                this.submitRegister(response.authResponse.userID);
              } else {
                ElMessage.error(this.$t("facebookExist"));
              }
              this.logoutClear();
            })
            .catch(() => {
              ElMessage.error(this.$t("registerFail"));
            });
        } else {
          ElMessage.error(this.$t("registerFail"));
        }
      });
    },
    existFbid(fbidValue) {
      axios
        .post(
          `https://php.rankopen.com/plugin.php?id=api&mode=pub&tab=register&action=existFbid`,
          JSON.stringify({ fbid: fbidValue })
        )
        .then((res) => {
          if (res.data == "pass") {
            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    },
    showToken() {
      ElMessage({
        message: localStorage.getItem("JWT_TOKEN")?.toString(),
        type: "success",
      });
    },
    resetToken() {
      localStorage.removeItem("JWT_TOKEN");
      localStorage.removeItem("uid");
      localStorage.removeItem("username");
      this.$router.push({ name: "tour" });
    },
    logout() {
      store.dispatch("setUsername", null);
      store.dispatch("setUid", null);
      store.dispatch("setToken", null);
    },
  },
});
